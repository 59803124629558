.woof .woof_redraw_zone .woof_container .woof_container_inner h4 {
    font-size: 1.8rem;
    line-height: 1.67;
    color: #000;
    border-bottom: 1px solid #d8d8d8;
}

.woof .woof_redraw_zone .woof_container .woof_container_inner ul {
    padding-left: 0;
}

.woof .woof_redraw_zone .woof_container .woof_container_inner ul li {
    font-size: 1.6rem;
    line-height: 1.5;
    color: #000;
}

.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_list_checkbox input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    border: 1px solid var(--color-webtim-default);
}

.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_list_checkbox input[type="checkbox"]:focus {
    outline: 1px solid var(--color-webtim-default);
    border-radius: 0;
}

.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_list_checkbox input[type="checkbox"]:checked::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '\f00c';
    font-family: 'Font Awesome 5 Free', curasive, sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
    color: var(--color-webtim-default);
    transform: translate(-50%, -50%);
}

.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_list_checkbox li label {
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
}

.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_childs_list_opener span.woof_is_closed,
.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_childs_list_opener span.woof_is_opened {
    position: relative;
    background: none;
}

.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_front_toggle.woof_front_toggle_closed,
.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_front_toggle.woof_front_toggle_opened,
.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_childs_list_opener span.woof_is_closed::before,
.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_childs_list_opener span.woof_is_opened::before {
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: var(--color-webtim-default);
}

.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_front_toggle.woof_front_toggle_closed,
.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_childs_list_opener span.woof_is_closed::before {
    content: '\f067';
}

.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_front_toggle.woof_front_toggle_opened,
.woof .woof_redraw_zone .woof_container .woof_container_inner .woof_childs_list_opener span.woof_is_opened::before {
    content: '\f068';
}