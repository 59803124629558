.webtim-shop--woof-filters__container {
    display: none;
}

@media (max-width: 800px) {
    aside#sidebar.sidebar > div.fusion-sidebar-inner-content {
        position: static !important;
    }

    aside#sidebar.sidebar > div.fusion-sidebar-inner-content .widget.widget-woof {
        margin-bottom: 0;
    }

    .webtim-shop--woof-filters__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-bottom: 12px;
    }

    .webtim-shop--woof-filters__container .menu-title {
        font-weight: bold;
    }

    .webtim-shop--woof-filters__container .chevron {
        position: relative;
        width: 22px;
        height: 22px;
        margin-left: 12px;
    }

    .webtim-shop--woof-filters__container .chevron::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: '';
        width: 9px;
        height: 9px;
        vertical-align: top;
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        transition: all .4s ease;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    .webtim-shop--woof-filters__container .chevron.down::before {
        margin-top: 0;
        transform: translate(-50%, -50%) rotate(135deg);
    }

    .woof {
        display: none;
    }

    .woof.visible {
        display: block;
    }
}